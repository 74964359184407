export default function responseHandler(response, key, innerKey, checkStatuses = {}) {
  if (!response) throw new Error('The web framework has failed to make the request');
  if (response.status >= 400) throw new Error(`Wrong Request, response: ${response.status}`);
  if (!response.data) throw new Error('Unsuccessful Request');

  const { checkSuccess, checkStatus, checkErrors, checkErrorsMessage } = checkStatuses;

  if (checkSuccess && response.data && !response.data.success && response.data.status !== 'success') {
    throw new Error(
      `Unsuccessful Request: ${response.data && response.data.message ? response.data.message : 'Empty errror'}`
    );
  }

  if (checkStatus && response.data.status !== 'success')
    throw new Error(`Unsuccessful Request: ${response.data && response.data.message}`);

  if (checkErrors && response.data.errors) throw new Error(`${checkErrorsMessage} has errors: ${response.data.errors}`);

  let output = key ? response.data[key] : response.data;

  return innerKey ? output[innerKey] : output;
}
