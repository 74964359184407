import { React, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getSiteContent, registerUser } from '../../_services/staff.service.js'
import classNames from 'classnames'
import styles from './Register.module.scss'
import Error from '../error/Error'

const Register = () => {
    const history = useHistory()
    const siteContent = getSiteContent()

    const currentLanguage = 'en'
    const isContent = siteContent !== null && !!Object.keys(siteContent)?.length &&
        typeof siteContent !== 'string' && !!siteContent?.[currentLanguage]?.content
    const data = !!isContent && Object.values(siteContent?.[currentLanguage]?.content)?.find(item => item.slug === 'home')
   
    const [form, setForm] = useState({
        name: { value: '', notValid: false },
        mobile: { value: '', notValid: false },
        company: { value: '', notValid: false },
        vehicle: '',
        plant: ''
    })

    const [notification, setNotification] = useState({
        isDisplayed: false,
        isSuccess: true,
        message: ''
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target

        setForm(prev => ({
            ...prev,
            [name]: !['vehicle', 'plant'].includes(name) ?
                { value, notValid: false }
                : value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const details = {
            name: form.name.value,
            mobile: form.mobile.value,
            company: form.company.value,
            vehicle_registration_number: form.vehicle,
            operating_plant: form.plant
        }

        const errorState = {}
        const filteredArray = Object.entries(form).filter(item => !['vehicle', 'plant']?.includes(item[0]))

        filteredArray.map(field => {
            if (!field[1]?.value && !['vehicle', 'plant']?.includes(field[1]?.value)) {
                errorState[field[0]] = {
                    value: field[1]?.value,
                    notValid: true
                }
            }
        })

        setForm(prev => ({ ...prev, ...errorState }))

        if (Object.values(errorState)?.every(item => !item.notValid)) {
            registerUser(details)
                .then((response) => {
                    history.push({
                        pathname: '/rules',
                        state: {
                            user: response
                        }
                    })
                })
                .catch(() => {
                    let errorMessage = data?.sections?.find(item => item.type === 'form')?.errorMessage

                    setNotification({
                        message: errorMessage,
                        isSuccess: false,
                        isDisplayed: true
                    })
                })
        }
    }

    return (
        (siteContent === null || typeof siteContent === 'string') ? (
            <Error />
        ) : (
            <div className={styles.pageWrapper}>
                <div className={styles.pageHeader}>
                    <h4 className={styles.title}>
                        {data?.title}
                    </h4>

                    <p className={styles.headerDescription}>
                        {data?.subtitle}
                    </p>
                </div>
                {data?.sections?.map((item, index) => (
                    <form key={index} className={styles.formWrapper}>
                        {item.content?.fields?.map((field, fieldIndex) => (
                            <div key={fieldIndex} className={classNames(styles.formControl, { [styles.notValid]: form[field.name]?.notValid })}>
                                <label>
                                    {field.label}
                                    {!!field.required && <span className={styles.asterisk}>*</span>}
                                </label>

                                {field?.type === 'radio' ? (
                                    <>
                                        <span className={styles.description}>{field.description}</span>

                                        <div className={styles.radioGroup}>
                                            {field.options?.map((item, index) =>
                                                <div key={index} className={styles.radioControl}>
                                                    <input
                                                        type={field.type}
                                                        name={field.name}
                                                        value={item?.value}
                                                        id={item?.value}
                                                        checked={form[field.name] === item?.value}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                    <label htmlFor={item?.value}>{item?.name}</label>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <input
                                            type={field.type}
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            value={form[field.name]?.value}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        {form[field.name]?.notValid && (
                                            <div className={styles.inputError}>{field.error}</div>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}

                        {notification.isDisplayed && (
                            <div className={styles.notificationHolder}>
                                <p className={classNames(styles.notification, notification.isSuccess ? [styles.success] : [styles.error])}>
                                    {notification.message}
                                </p>
                            </div>
                        )}

                        <div className={styles.actions}>
                            <button
                                className={classNames(styles.button, styles.primary)}
                                type="button"
                                onClick={(e) => handleSubmit(e)}
                            >
                                {item?.content?.submitButton}
                            </button>
                        </div>
                    </form>
                ))}
            </div>
        )
    )
}

export default Register