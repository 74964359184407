import { React, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import { confirmAlert } from 'react-confirm-alert'
import {
    MESSAGE_COVID_OTHER_SYMPHTOMS,
    QUESTION_COVID_OTHER_SYMPHTOMS
} from '../../_utils/constants'
import { submitRules, getUserInfo, getSiteContent } from '../../_services/staff.service.js'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styles from './Rules.module.scss'
import Error from '../error/Error'

const Rules = ({ registered }) => {
    const history = useHistory()
    const userData = getUserInfo()
    const siteContent = getSiteContent()

    const currentLanguage = 'en'
    const isContent = siteContent !== null && !!Object.keys(siteContent)?.length &&
        typeof siteContent !== 'string' && !!siteContent?.[currentLanguage]?.content
    const data = !!isContent && Object.values(siteContent?.[currentLanguage]?.content)?.find(item => item.slug === 'rules')

    const [safety, setSafety] = useState({ checked: false })
    const [otherSymptoms, setOtherSymptoms] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [notification, setNotification] = useState({
        isDisplayed: false,
        isSuccess: true,
        message: ''
    })

    const pageNumber = data?.sections?.length

    useEffect(() => {
        if (!!userData && userData.exp < moment(new Date()).unix()) {
            changeUser()
        } else {
            registered ? goPage(pageNumber) : goPage(currentPage)
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])

    const goPage = (page) => {
        setCurrentPage(page)

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const changeUser = () => {
        localStorage.removeItem('registration')
        history.push('/')
        window.location.reload()
    }

    const submit = () => {
        let params = {
            health_check: {
                other_symptoms: otherSymptoms
            },
            agree_safety_induction: !!safety.checked ? 'yes' : 'no'
        }

        return submitRules(params)
            .then((response) => {
                history.push({
                    pathname: '/confirmation',
                    state: {
                        user: response
                    }
                })
            })
            .catch(() => {
                let errorMessage = data?.sections?.find(item => item.type === 'form')?.errorMessage

                setNotification({
                    message: errorMessage,
                    isSuccess: false,
                    isDisplayed: true
                })
            })
    }

    const onChangeOtherSymptoms = (e) => {
        setOtherSymptoms(e.target.value)
    }

    const onYesCheck = (e, onChange, question, confirmation) => {
        e.preventDefault()
        confirmAlert({
            title: 'Confirm',
            message: question,
            buttons: [
                {
                    className: 'button primary',
                    label: 'Yes',
                    onClick: () => {
                        setTimeout(() => {
                            confirmAlert({
                                message: confirmation,
                                buttons: [
                                    {
                                        className: 'button primary',
                                        label: 'Ok',
                                        onClick: () => onChange(e)
                                    }
                                ]
                            })
                        }, 0)
                    }
                },
                {
                    className: 'button primary',
                    label: 'No',
                    onClick: () => { }
                }
            ]
        })
    }

    const enableHealth = () => {
        return otherSymptoms.length
    }

    const enableSubmit = () => {
        return safety.checked && enableHealth()
    }

    return (
        (siteContent === null || typeof siteContent === 'string') ? (
            <Error />
        ) : (
            <div className={styles.pageWrapper}>
                <div className={styles.pageHeader}>
                    <h4 className={styles.title}>{data?.title}</h4>
                </div>

                <div className={styles.contentWrapper}>
                    {!!userData && userData.name && (
                        <div className={styles.userName}>
                            <span>
                                {data?.details?.title} {userData.name}
                                {' '} (...{userData.mobile.substring(userData.mobile.length - 3)})
                            </span>
                            <span
                                className={classNames(styles.linkText, styles.changeUser)}
                                onClick={changeUser}>
                                {data?.details?.action}
                            </span>
                        </div>
                    )}

                    <div className={styles.contentData}>
                        <div className={styles.contentTitle}>{data?.sections[currentPage - 1]?.title}</div>
                        {data?.sections[currentPage - 1]?.type === 'page' ? (
                            <div dangerouslySetInnerHTML={{ __html: data?.sections[currentPage - 1]?.content }}></div>
                        ) : (
                            <div className={styles.contentData}>
                                {data?.sections[currentPage - 1].content?.fields?.map((field, fieldIndex) => (
                                    <div key={fieldIndex} className={styles.formGroup}>
                                        {field?.type === 'radio' && (
                                            <div className={styles.formGroup}>
                                                <span className={classNames(styles.title, styles.mandatory)}>
                                                    {field.label}
                                                    {!!field.required && <span className={styles.asterisk}>*</span>}
                                                </span>
                                                <div className={styles.radioGroup}>
                                                    {field.options?.map((item, index) =>
                                                        <div key={index} className={styles.radioControl}>
                                                            <input
                                                                type={field.type}
                                                                name={field.name}
                                                                value={item?.value}
                                                                id={item?.value}
                                                                checked={otherSymptoms === item?.value}
                                                                onChange={(e) => item?.value === 'no'
                                                                    ? onChangeOtherSymptoms(e)
                                                                    : onYesCheck(e, onChangeOtherSymptoms, QUESTION_COVID_OTHER_SYMPHTOMS, MESSAGE_COVID_OTHER_SYMPHTOMS)
                                                                }
                                                            />
                                                            <label htmlFor={item?.value}>{item?.name}</label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className={styles.pagination}>
                        <span className={styles.page}>
                            {currentPage > 1 && (
                                <span
                                    className={classNames(styles.linkText, { [styles.disabled]: currentPage === 1 })}
                                    onClick={() => goPage(currentPage - 1)}>
                                    {data?.pagination?.previous}
                                </span>
                            )}
                        </span>

                        <span
                            className={styles.currentPage}>
                            {currentPage}
                        </span>

                        <span className={styles.page}>
                            {currentPage < pageNumber && (
                                <span
                                    onClick={() => goPage(currentPage + 1)}
                                    className={
                                        classNames(styles.linkText,
                                            { [styles.disabled]: currentPage === 9 })
                                    }>
                                    {data?.pagination?.next}
                                </span>
                            )}
                        </span>
                    </div>
                </div>

                {currentPage === pageNumber && (
                    <div className={styles.actions}>
                        <div className={styles.checkControls}>
                            {data?.sections[currentPage - 1]?.content?.fields?.map((field, index) => (
                                <div key={index} className={styles.formControl}>
                                    {field.type === 'checkbox' && (
                                        <>
                                            <label className={classNames(styles.control, styles.controlCheckbox)}>
                                                {field.label}
                                                {!!field.required &&
                                                    <span className={styles.asterisk}>*</span>}
                                                <input
                                                    type={field.type}
                                                    checked={safety.checked}
                                                    id={field.name}
                                                    onChange={() => setSafety({ checked: !safety.checked })} />
                                                <span className={styles.control__indicator}></span>
                                            </label>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>

                        {notification.isDisplayed && (
                            <div className={styles.notificationHolder}>
                                <p className={classNames(styles.notification, notification.isSuccess ? [styles.success] : [styles.error])}>
                                    {notification.message}
                                </p>
                            </div>
                        )}
                        <button
                            className={classNames(styles.button, styles.primary)}
                            type="submit"
                            disabled={!enableSubmit()}
                            onClick={submit}>
                            {data?.sections[currentPage - 1]?.content?.submitButton}
                        </button>
                    </div>
                )}
            </div>
        )
    )
}

export default Rules