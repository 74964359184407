import { React, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { getSiteContent, getUserInfo } from '../../_services/staff.service.js'
import Error from '../error/Error'
import styles from './Confirmation.module.scss'

const Confirmation = () => {
    const location = useLocation()
    const userData = getUserInfo()

    const currentLanguage = 'en'
    const siteContent = getSiteContent()
    const isContent = siteContent !== null && !!Object.keys(siteContent)?.length &&
        typeof siteContent !== 'string' && !!siteContent?.[currentLanguage]?.content
    const data = !!isContent && Object.values(siteContent?.[currentLanguage]?.content)?.find(item => item.slug === 'confirmation')

    const user = location.state && location.state.user
    const otherSymptomsWarning = user?.other_symptoms === 'yes'

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    
    return (
        (siteContent === null || typeof siteContent === 'string') ? (
            <Error />
        ) : (
            <div className={styles.pageWrapper}>
                <div className={styles.pageHeader}>
                    <h4 className={styles.title}>{data?.title}</h4>
                </div>
                <div className={styles.contentWrapper}>
                    {data?.sections?.map((item, index) => (
                        <>
                            {item.type === 'error' &&
                                !user && (
                                    <>
                                        <div className={styles.contentIcon}>
                                            <img alt="tick" className="tick"
                                                src={item?.content?.icon} />
                                        </div>
                                        <span className={styles.successMessage}>{item?.content?.title}</span>
                                        <span>{item.content?.description}</span>
                                    </>
                                )
                            }

                            {item.type === 'success' &&
                                !!user && !otherSymptomsWarning && (
                                    <>
                                        <div className={styles.contentIcon}>
                                            <img alt="tick" className="tick"
                                                src={item?.content?.icon} />
                                        </div>
                                        {!!userData && userData.name && (
                                            <div className={styles.contentFooter}>
                                                <span>
                                                    {userData.name}
                                                    {' '} (...{userData.mobile.substring(userData.mobile.length - 3)})
                                                </span>
                                                <span className={styles.dateSession}>
                                                    {moment(userData.createdAt).format(' h:mm:ss a, DD MMMM YYYY')}
                                                </span>
                                            </div>
                                        )}
                                        <div className={styles.pageFooter}>
                                            <span>{item?.content?.description}</span>
                                        </div>
                                        {user.session_id && (
                                            <span className={styles.userSession}>{item?.content?.details} {user.session_id}</span>
                                        )}
                                    </>
                                )
                            }

                            {item.type === 'warning' &&
                                !!user && !!otherSymptomsWarning && (
                                    <>
                                        <div className={styles.contentIcon}>
                                            <img alt="tick" className="tick"
                                                src={item?.content?.icon} />
                                        </div>
                                        <span className={styles.warningMessage}>{item?.content?.details}</span>
                                    </>
                                )
                            }

                            {/* {!!user && user.lateral_flow_test && (
                            <>
                                <span className={styles.successMessage}>
                                    Thank you
                                </span>
                            </>
                        )} */}
                        </>
                    )
                    )}
                </div>
            </div>
        )
    )
}

export default Confirmation