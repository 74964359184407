import axios from 'axios';
import jwtDecode from 'jwt-decode';

import responseHandler from './responseHandler.service';
import config from '../env-config';

export function registerUser(data) {
    return axios
        .request({
            url: [config.services.api, 'staff/register'].join('/'),
            method: 'post',
            data,
            headers: {}
        })
        .then((resp) => {
            localStorage.setItem('registration', resp.data.token);
            return Promise.resolve(jwtDecode(resp.data.token))
        })
        .catch((e) => {
            return Promise.reject(e);
        });
}

export function submitRules(data) {
    const token = localStorage.getItem('registration');

    return axios
        .request({
            url: [config.services.api, 'staff/submit'].join('/'),
            method: 'post',
            data,
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(responseHandler)
        .catch((e) => {
            return Promise.reject(e);
        });
}

export const fetchSiteContent = async () => {
    try {
        const response = await fetch([config.services.apiContent, 'content/taste_staff/site_content'].join('/'))
        const resultData = await response.json()

        return resultData
    }
    catch (error) {
        return error?.message
    }
}

export function getUserInfo() {
    const token = localStorage.getItem('registration');

    return token ? jwtDecode(token) : {};
}

export const getSiteContent = () => {
    try {
        return JSON.parse(localStorage.getItem("siteContent"));
    }
    catch (error) {
        return error?.message
    }
}