import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { history } from './_utils/history'
import { fetchSiteContent } from './_services/staff.service'
import './styles/App.scss'

import Header from './_components/header/Header'
import Footer from './_components/footer/Footer'
import Register from './pages/register/Register'
import Rules from './pages/rules/Rules'
import Confirmation from './pages/confirmation/Confirmation'
import Error from './pages/error/Error'

export default function App() {
    const registered = localStorage.getItem('registration')
    const [siteContent, setSiteContent] = useState(null)
    const [errorMessage, setErrorMessage] = useState(false)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!siteContent?.length) {
            fetchSiteContent()
                .then((data) => {
                    if (!!data?.problem || data?.status === 'error' || typeof data === 'string') {
                        setErrorMessage(true)
                    } else {
                        if (typeof localStorage !== "undefined") {
                            localStorage.setItem("siteContent", JSON.stringify(data))
                            setSiteContent(data)
                        }
                    }
                })
        }
    }, [])

    useEffect(() => {
        if (!!siteContent) {
            setLoading(false)
        }
    }, [siteContent])

    return (
        <Router history={history}>
            <Header />
            {!!loading ? (
                <div className="loading-page">
                    <div className="loader"></div>
                </div>
            ) : (
                <section className='container'>
                    {!errorMessage ?
                        <Switch>
                            <Route exact path="/">
                                {!!registered
                                    ? <Rules registered={true} />
                                    : <Register />
                                }
                            </Route>
                            <Route exact path="/rules">
                                <Rules />
                            </Route>
                            <Route exact path="/confirmation">
                                <Confirmation />
                            </Route>
                            <Route path="*">
                                <Error />
                            </Route>
                        </Switch>
                        : <Error />
                    }
                </section>
            )}
            <Footer />
        </Router>
    )
}
