import { React } from 'react'
import styles from './Header.module.scss'
import { getSiteContent } from '../../_services/staff.service'

const Header = () => {
    const siteContent = getSiteContent()
    const isContent = siteContent !== null && !!Object.keys(siteContent)?.length
    const currentLanguage = 'en'
    const data = !!isContent && siteContent?.[currentLanguage]?.header

    return (
        <header className={styles.appHeader}>
            <div className={styles.tasteLogo}>
                <img src={data?.logo} alt="Taste"/>
            </div>
        </header>
    )
}

export default Header
