import { React, useEffect } from 'react'
import styles from './Error.module.scss'

const Confirmation = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.errorWrapper}>
               <p>Oops! Something went wrong!</p>
            </div>
        </div>
    )
}

export default Confirmation