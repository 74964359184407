import { React, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './Footer.module.scss'
import { getSiteContent } from '../../_services/staff.service'

const Footer = () => {
    const siteContent = getSiteContent()

    const currentLanguage = 'en'
    const isContent = siteContent !== null && !!Object.keys(siteContent)?.length &&
        typeof siteContent !== 'string' && !!siteContent?.[currentLanguage]?.content

    const data = !!isContent && siteContent?.[currentLanguage]?.footer
    const [footer, setFooter] = useState([])

    useEffect(() => {
        setFooter(data?.footer_menu)
    }, [isContent])

    const toggleCollapse = (value) => {
        const findItem = footer?.find(item => item.value === value)

        setFooter(footer?.map((item) => ({
            ...item,
            collapsed: item.value === findItem.value ? !findItem.collapsed : item.collapsed
        })))
    }

    return (
        <footer className={styles.appFooter}>
            <div className={styles.footerNavs}>
                {footer?.map((item, index) => (
                    <div
                        key={index}
                        className={classNames(styles.footerNav, { [styles.expanded]: item?.collapsed })}>
                        <div
                            className={styles.footerNav__title}
                            onClick={() => toggleCollapse(item.value)}
                        >
                            <span>{item.title}</span>
                            <span className={styles.chevronIcon}></span>
                        </div>
                        <div className={styles.footerNav__content}>
                            {!!item.collapsed && (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: item.content }}></div>

                                    <button
                                        onClick={() => toggleCollapse(item.value)}
                                        className={classNames(styles.button, styles.primary)}>
                                        {item.action.title}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.tasteLogo}>
                <img src={data?.rights?.logo} alt="Taste" />
                <p className={styles.appRights}>{data?.rights?.text}</p>
            </div>
            <div className={styles.poweredBy}>
                {data?.powered?.text}
                <img
                    src={data?.powered?.logo}
                    className="logo"
                    alt="Fabacus" />
            </div>
        </footer>
    )
}

export default Footer
